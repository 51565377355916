<template>
    <div>
        <v-row no-gutters fluid class="spacing-playground my-6 p-0">
            <v-col cols="12" sm="8">
                <div class="text-sm-h4">Настройки</div>
            </v-col>
            <v-col cols="12" sm="4" align="right">
                <v-btn color="primary" elevation="1" large v-on:click="saveSettings" :loading="saving" :disabled="!isChanged">Сохранить</v-btn>

            </v-col>
        </v-row>
        <v-card outlined v-if="!loading">
            <v-card-title>
                Общие
            </v-card-title>
            <v-card-text>
                <v-text-field
                        label="Номер телефона (Отображается в меню мобильной версии)"
                        v-model="extra.phone"
                        outlined
                ></v-text-field>
                <v-text-field
                        label="Заголовок раздела проекты"
                        v-model="extra['projects-title']"
                        outlined
                ></v-text-field>
            </v-card-text>

            <v-card-title>
                Социальные сети
            </v-card-title>
            <v-card-text>
                <tbody ref="sort">
                <tr
                        v-for="(item, index) in social.social"
                        class="sortableRow"
                        :key="item.id"
                >
                    <td class="px-1" style="width: 0.1%">
                        <v-btn style="cursor: move" icon class="sortHandle"><v-icon>mdi-drag</v-icon></v-btn>
                    </td>
                    <td style="max-width: 100px">
                        <v-text-field label="Название" v-model="item.name" dense class="pt-5 mr-5" />
                    </td>
                    <td>
                        <v-text-field label="Ссылка" v-model="item.url" dense class="pt-5 mr-5" />
                    </td>
                    <td class="soc">
<!--                        <v-text-field label="Иконка" v-model="item.icon" dense class="pt-5 mr-5" />-->
                        <v-select
                                v-model="item.icon"
                                :items="icons.map(i => i.properties.name)"
                                chips
                                label="Иконка"
                        ></v-select>
                        <div class="soc-icon"><svg width="24" height="24" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg"><path v-for="path in icons.find(i => i.properties.name === item.icon)['icon']['paths']" :d="path" fill="black"></path></svg></div>
                    </td>
                    <td style="text-align: end;">
                        <v-icon v-on:click="removeSocialItem(index)" color="gray" >mdi-delete-circle-outline</v-icon>
                    </td>
                </tr>
                </tbody>
                <v-btn v-on:click="addNewSocialItem">Добавить</v-btn>
            </v-card-text>

        </v-card>

        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>

    </div>

</template>

<script>
    import {db} from '../firebase';
    import * as icomoon from '../assets/icomoon';
    import Sortable from 'sortablejs';


    export default {
        name: "Settings",
        data() {
            return {
                extra: [],
                social: [],
                saveDisabled: true,
                startedData: [],
                loading: true,
                icons: [],
                saving: false
            }
        },
        methods: {
            async getData(doc) {
                return db.collection('settings').doc(doc).get()
            },
            async saveData(doc) {
                return db.collection('settings').doc(doc).set(this[doc])
            },
            saveSettings() {
                let that = this;
                this.saving = true
                Promise.all([this.saveData('extra'), this.saveData('social')])
                    .then(function () {

                        that.$store.commit('alert', { message: 'Настройки обновлены.'});
                    })
                    .catch(function () {
                        that.$store.commit('alert', { message: 'Ошибка!'});
                    })
                    .finally(function () {
                        that.startedData = that.cloneObject(that.allData)
                        that.saving = false
                    })
            },
            removeSocialItem(index) {
                if (confirm('Удалить?')) {
                    this.social.social.splice(index, 1)
                }
            },
            addNewSocialItem() {
                this.social['social'].push({id: this.random(6), icon: 'fb', name: '', url: ''})
            },
            dragReorder({oldIndex, newIndex}) {
                const movedItem = this.social['social'].splice(oldIndex, 1)[0]
                this.social['social'].splice(newIndex, 0, movedItem)
            },
        },
        computed: {
            allData() {
                return [this.extra, this.social]
            },
            isChanged() {
                return this.compareObjects(this.allData, this.startedData)
            }
        },
        mounted() {
            this.icons = icomoon.default.icons;

            Promise.all([this.getData('extra'), this.getData('social')]).then(res => {
                res.forEach(r => {
                    if (!r) return;
                    if (r['id'] === 'extra') this.extra = r.data();
                    if (r['id'] === 'social') this.social = r.data();
                })
            })
                .finally(() => {
                    this.startedData = this.cloneObject(this.allData)
                    this.loading = false
                    initSort()
                })


            const that = this;
            // Init sorting
            function initSort() {
                that.$nextTick(function () {
                    Sortable.create(that.$refs.sort, {
                        draggable: '.sortableRow',
                        handle: '.sortHandle',
                        onEnd: that.dragReorder,
                    });
                })
            }

        },
    }
</script>

<style scoped>
    .soc-icon {
        position: absolute;
        top: 24px;
        left: 0;
    }
    .soc {
        position: relative;
        max-width: 80px;
    }
    .soc >>> .v-select__selections {
        padding-left: 24px
    }

</style>
